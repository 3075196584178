<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>스피커관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 1200px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 280px; margin-left: 30px">
							<div class="form-group">
								<label>{{ detailsFieldMap.siteNm }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.siteNm">
										<option value="">전체</option>
										<option v-for="(row, index) in options.siteOptions" :key="index" :value="row.siteNm">{{ row.siteNm }}</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-3" style="width: 270px">
							<div class="form-group">
								<label>{{ detailsFieldMap.speakerNm }}</label>
								<input
									type="text"
									class="form-control"
									id="startInput"
									style="width: 210px"
									:placeholder="detailsFieldMap.speakerNm"
									v-model="searchVM.speakerNm"
								/>
							</div>
						</div>
						<!-- 사용여부 -->
						<div class="col-md-3" style="width: 220px">
							<div class="form-group">
								<label>{{ detailsFieldMap.useYn }}</label>
								<select2 v-model="searchVM.useYn" :options="options.ynOptions">
									<option value="">전체</option>
								</select2>
							</div>
						</div>
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								스피커 관리 정보
							</legend>
							<div class="col-md-6">
								<!-- 스피커 ID, 수정모드만 표시 -->
								<div v-if="isEditMode">
									<jarvis-field
										:label="detailsFieldMap.speakerId"
										v-if="isEditMode"
										required="true"
										disabled="true"
										field="detailsItem.speakerId"
										v-model="detailsItem.speakerId"
										data-vv-name="detailsItem.speakerId"
									></jarvis-field>
								</div>
								<!-- 스피커명 -->
								<jarvis-field
									:label="detailsFieldMap.speakerNm"
									id="firstInputCreate"
									required="true"
									field="detailsItem.speakerNm"
									v-model="detailsItem.speakerNm"
									data-vv-name="detailsItem.speakerNm"
									v-validate="validationRule.detailsItem.speakerNm"
								></jarvis-field>
								<!-- 현장 ID. 등록시만 입력 가능하게 -->
								<jarvis-field :label="detailsFieldMap.siteNm" field="detailsItem.siteNm" required="true" v-if="isCreateMode">
									<select2
										v-model="detailsItem.siteId"
										data-vv-name="detailsItem.siteNm"
										v-validate="validationRule.detailsItem.siteId"
										:options="options.siteOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 현장명 - 수정시에는 보여주기만 -->
								<jarvis-field :label="detailsFieldMap.siteNm" field="detailsItem.siteNm" v-if="!isCreateMode">
									<select2 v-model="detailsItem.siteId" data-vv-name="detailsItem.siteNm" :options="options.siteOptions" disabled></select2>
								</jarvis-field>
								<!-- 스피커 재생 URL -->
								<jarvis-field
									:label="detailsFieldMap.speakerPlayUrl"
									field="detailsItem.speakerPlayUrl"
									v-model="detailsItem.speakerPlayUrl"
									data-vv-name="detailsItem.speakerPlayUrl"
									v-validate="validationRule.detailsItem.speakerPlayUrl"
								></jarvis-field>
								<!-- 재생 음량 -->
								<jarvis-field
									:label="detailsFieldMap.playVolumn"
									required="true"
									field="detailsItem.playVolumn"
									v-model="detailsItem.playVolumn"
									data-vv-name="detailsItem.playVolumn"
									v-validate="validationRule.detailsItem.playVolumn"
									maxLength="3"
								></jarvis-field>
								<!-- 사용여부 -->
								<jarvis-field :label="detailsFieldMap.useYn" field="detailsItem.useYn" required="true">
									<select2
										v-model="detailsItem.useYn"
										data-vv-name="detailsItem.useYn"
										v-validate="validationRule.detailsItem.useYn"
										:options="options.ynOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>
						</fieldset>
					</div>

					<div class="row"></div>
					<!-- 버튼 추가모드만 표시 -->
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<!-- 버튼 수정모드만 표시 -->
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
const speakerApi = apiIndex.speaker;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		speakerNm: 'max:50|required',
		siteId: 'required',
		speakerPlayUrl: 'max:200',
		playVolumn: 'max:3|numOnly|required',
		useYn: 'required',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('speakerPageParam'));

		if (!this.pageParam) location.href = apiIndex.mainUrl;

		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });

		axiosExtention = this.$jarvisExtention.axiosExtention;

		//기본 현장 세팅
		this.searchVM.siteNm = this.pageParam.siteOptions.filter(e => e.siteId == this.pageParam.loginUserSiteId)[0].siteNm;
		this.searchVM.useYn = '1'; // 사용
		// jarvis-field에서 사용하기 위함
		this.pageParam.siteOptions.forEach(e => {
			e.text = e.siteNm;
			e.value = e.siteId;
		});

		this.pageParam.ynOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		this.options = { siteOptions: this.pageParam.siteOptions, ynOptions: this.pageParam.ynOptions };
		this.gridColumns.filter(item => item.field == 'useYn')[0].mapData = this.options.ynOptions;
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});

		this.search();
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			pageListApi: speakerApi.inqSpeaker,
		},
		searchVM: {
			siteNm: null,
			speakerNm: null,
			useYn: null,
		},
		options: {},
		searchState: {}, //적용된 검색조건
		//그리드설정
		gridColumns: [
			{ field: 'siteNm', title: '현장명', width: '15%' },
			{ field: 'speakerNm', title: '스피커명', width: '15%' },
			{ field: 'speakerPlayUrl', title: '재생 URL', width: '40%' },
			{ field: 'playVolumn', title: '재생음량', width: '7%', align: 'right' },
			{ field: 'useYn', title: '사용여부', width: '7%', align: 'center', dataFormat: 'optionMap' },
			{
				field: 'regDtm',
				title: '등록일',
				width: '10%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{
				field: 'updDtm',
				title: '수정일',
				width: '10%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
		],
		//상세필드명맵
		detailsFieldMap: {
			speakerId: '스피커ID',
			speakerNm: '스피커명',
			siteNm: '현장명',
			speakerPlayUrl: '재생 URL',
			playVolumn: '재생음량',
			useYn: '사용여부',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '최종 수정일',
			updUser: '최종 수정자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		debug: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				rule = detailsValidationRule.edit;
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: speakerApi.exlSpeaker,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '스피커관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.$axios
					.post(speakerApi.inqOneSpeaker, { speakerId: selectedRowItem.speakerId })
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
							this.detailsItem = $.extend(true /*deep*/, {}, response.data);
							console.log(this.detailsItem);
							setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				speakerNm: '',
				siteId: '',
				speakerPlayUrl: '',
				playVolumn: null,
				usyYn: null,
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//신규저장
		createData() {
			this.$validator.validateAll().then(
				function (success) {
					if (success == false) {
						return;
					}
					this.$axios
						.post(speakerApi.insSpeaker, this.detailsItem)
						.then(
							function () {
								this.loadGrid();
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
				}.bind(this),
			);
		},
		//수정저장
		updateData() {
			this.$validator.validateAll().then(
				function (success) {
					if (success == false) {
						return;
					}
					if (confirm('수정한 데이터를 저장하시겠습니까?')) {
						const speakerId = this.detailsItem.speakerId;
						this.$axios
							.put(speakerApi.updSpeaker + '/' + speakerId, this.detailsItem)
							.then(
								function () {
									this.loadGrid();
								}.bind(this),
							)
							.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
					}
				}.bind(this),
			);
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			const speakerId = this.detailsItem.speakerId;
			this.$axios
				.delete(speakerApi.delSpeaker + '/' + speakerId)
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
.select-wrapper {
	min-width: 210px !important;
	width: 210px !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}
</style>
